@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");

.headFont {
  font-family: "Prompt", sans-serif;
}

.textScript {
  font-family: "Prompt", sans-serif;
}
.textScriptFooter {
  font-family: "Prompt", sans-serif;
}
